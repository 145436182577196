import { isDefined } from "@clipboard-health/util-ts";
import { useWorkerPreferences } from "@src/appV2/Agents/api/useWorkerPreferences";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useWorkerGeoLocation } from "@src/appV2/Worker/useWorkerGeoLocation";

import { useHomeHealthStatistics } from "../../lib/useHomeHealthStatistics";
import { useIsHomeHealthEnabled } from "../../lib/useIsHomeHealthEnabled";

export function useHomeHealthVisibility() {
  const isHomeHealthEnabled = useIsHomeHealthEnabled();
  const worker = useDefinedWorker();
  const { filters } = useWorkerPreferences();
  const workerGeoLocation = useWorkerGeoLocation();

  const { data: homeHealthStatistics } = useHomeHealthStatistics(
    {
      filter: {
        longitude: workerGeoLocation?.longitude,
        latitude: workerGeoLocation?.latitude,
        maxDistanceInMiles: filters?.distance,
        qualifications: worker.licensesData.map((license) => license.qualification).join(","),
      },
    },
    worker.userId,
    {
      enabled:
        isDefined(filters) &&
        isDefined(workerGeoLocation) &&
        isHomeHealthEnabled &&
        worker.licensesData.length > 0,
    }
  );

  const { bookedVisits, openVisitsCount } = homeHealthStatistics?.data?.attributes ?? {};

  const shouldShowHomeHealth = isHomeHealthEnabled && isDefined(bookedVisits) && bookedVisits > 0;

  return {
    isHomeHealthEnabled,
    shouldShowHomeHealth,
    homeHealthCount: openVisitsCount,
  };
}
